<template>
  <div>
    <b-card>
      <!-- User Info: Input Fields -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Spreadsheet ID -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Spreadsheet ID"
                rules="required"
              >
                <b-form-group
                  label="Spreadsheet ID"
                  label-for="spreadsheet_id"
                >
                  <b-form-input
                    id="spreadsheet_id"
                    v-model="importDataObject.spreadsheet_id"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Type -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Modules.Customer.Label.Type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Modules.Customer.Label.Type')"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="importDataObject.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: User -->
            <b-col
              cols="12"
              md="4"
              v-if="importDataObject.type == 'potential' || importDataObject.type == 'hothit'"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('User')"
                rules="required"
              >
                <b-form-group
                  :label="$t('User')"
                  label-for="user"
                >
                  <v-select
                    v-model="importDataObject.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            {{ $t('Import') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
  BFormInvalidFeedback, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import UserRepository from '@/modules/user/repository/userRepository'
import CustomerRepository from '@/modules/customer/repository/customerRepository'
import { required } from '@validations'

export default {
  components: {
    BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
    BFormInvalidFeedback, BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required
    }
  },
  setup(props, context) {
    const vm = context.root
    var importData = {
      spreadsheet_id: '',
      type: '',
      user_id: null
    }
    var typeOptions = [
      { label: vm.$i18n.t('work_phone'), value: 'work_phone' },
      { label: vm.$i18n.t('cell_phone'), value: 'cell_phone' },
      { label: vm.$i18n.t('potential'), value: 'potential' },
      { label: vm.$i18n.t('hothit'), value: 'hothit' },
    ];
    const userOptions = ref([])
    UserRepository.listUserByRoleTitle({roleTitle: 'Sales Staff, Customer Services'})
      .then(response => {
        var users = response.data.data.users;
        userOptions.value = []
        users.forEach((item, index) => {
          userOptions.value.push({
            label: item.shortName,
            value: item.id
          })
        })
      })

    const importDataObject = ref(JSON.parse(JSON.stringify(importData)))
    const resetImportData = () => {
      importDataObject.value = JSON.parse(JSON.stringify(importData))
    }
    const onSubmit = () => {
      CustomerRepository.import(importDataObject.value)
        .then(() => {})
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetImportData)

    return {
      typeOptions,
      userOptions,
      importDataObject,

      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  }
}
</script>